.banner-precaucion {
  background: #1d2427;
  text-align: center;
  color: white;
  height: 22px;
  font-size: 1em;
}
@media screen and (max-width: 768px) {
  .banner-precaucion {
    height: 12px;
    font-size: 0.55em;
  }
}

.warning-icon {
  width: 18px;
  margin-right: 10px;
  margin-bottom: 5px;
}

@media screen and (max-width: 768px) {
  .warning-icon {
    width: 10px;
    margin-right: 4px;
    margin-top: 1px;
  }
}
