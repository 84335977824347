.tabs {
  margin: 2rem 0; }

.product-considerations {
  margin-top: 3rem;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  padding: 1rem 0;
  display: flex;
  align-items: center; }
  .product-considerations .one-fourth {
    height: 150px;
    display: flex;
    border-right: 1px solid #c2c2c2;
    width: 25%;
    font-size: 1.2rem;
    line-height: 2rem;
    align-items: center; }
    .product-considerations .one-fourth .text-container {
      padding: 0 10px;
      text-align: center; }
    .product-considerations .one-fourth .image-container {
      width: 100%;
      text-align: center; }
      .product-considerations .one-fourth .image-container img {
        position: relative;
        height: 130px; }
  .product-considerations .one-half {
    display: inline-block;
    width: 50%;
    font-size: 1.4rem;
    line-height: 2.2rem; }
    .product-considerations .one-half p {
      margin-left: 20px; }

.products-container {
  margin-top: 50px; }

@media screen and (max-width: 900px) {
  .screen-contents.quote-screen {
    margin: 0; }
    .screen-contents.quote-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
  .screen-contents {
    margin: 2rem 2rem 10rem 2rem; }
  .product-considerations {
    flex-direction: column; }
    .product-considerations .one-fourth {
      height: 80px;
      display: flex;
      width: 100%;
      text-align: center;
      align-self: center;
      border-right: none;
      font-size: 1.2rem;
      line-height: 2rem;
      align-items: center; }
    .product-considerations .text-container {
      padding: 0 10px;
      text-align: center !important; }
    .product-considerations .one-half {
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      font-size: 1.4rem;
      line-height: 2.2rem; }
      .product-considerations .one-half p {
        margin-left: 0px; } }
