.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #86b127;
  width: 100%;
  height: 14rem; }
  .banner p {
    margin: 0;
    color: white; }

.banner .info-title {
  color: white;
  font-weight: bolder;
  font-size: 3.5rem !important; }

.banner-button {
  background: transparent;
  color: white;
  font-size: 1rem;
  border: 2px solid white;
  border-radius: 18px;
  margin-top: 20px;
  width: 200px;
  text-align: center;
  display: table-cell; }

.banner-responsive {
  display: none; }

.banner .banner-button a:link,
.banner .banner-button a:visited,
.banner .banner-button a:hover {
  text-decoration: none !important;
  color: white !important; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .banner p {
    padding: 10px 30px;
    line-height: 2rem;
    font-size: 2rem;
    text-align: center; } }

@media screen and (max-width: 900px) {
  .banner-responsive {
    display: inherit !important; }
  .banner .info-title {
    font-size: 1.9rem !important;
    padding: 20px;
    line-height: 2rem;
    text-align: center; }
  .banner .row {
    margin-bottom: 10px;
    text-align: center; }
  .banner {
    height: 100%;
    letter-spacing: normal; }
  .banner p {
    font-size: 11px;
    margin-top: -3em;
    font-weight: 800; }
  .banner .row-button {
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center; }
  .banner .banner-button {
    font-size: 0.9em;
    margin: 1em;
    width: 15em !important;
    border-radius: 6px; }
  .banner .banner-button a:link,
  .banner .banner-button a:visited,
  .banner .banner-button a:hover {
    text-decoration: none !important;
    color: white !important; } }
