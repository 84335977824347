.summary {
  padding: 1rem 5rem 2rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 3em; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary {
    padding: 1rem 3rem 2rem; } }
