.spinner {
  margin-left: calc(50% - 10px);
  margin-top: 18% !important; }

.content-header .content-title h1 {
  color: #1b802e;
  font-size: 2.1em;
  font-weight: bolder !important;
  margin: 0 0 2rem !important; }

.content-header .content-text {
  color: #1b802e;
  font-size: 1.2em;
  margin-top: -36px !important;
  margin-bottom: 16px; }

.container-table {
  overflow: scroll; }

.content-bread {
  background-color: #f4f4f4;
  border-radius: 5px;
  padding: 7px 0 1px 19px;
  vertical-align: middle;
  color: grey;
  font-weight: bolder;
  margin-bottom: 5px;
  margin-top: 2em; }

.container-pickup table thead tr th {
  text-align: center;
  color: grey;
  font-weight: bolder;
  background-color: transparent !important; }

.th-left {
  text-align: left !important; }

.container-pickup .container-table {
  height: 400px;
  padding: 0.5em; }

.container-pickup table tbody tr td {
  font-size: 0.8em !important;
  padding: 0.5em;
  font-weight: bolder;
  color: grey; }

.container-pickup table tbody tr {
  border-bottom: 1px solid #efe9e9; }

.container-pickup .special-border1 {
  text-align: center; }

.container-pickup .special-border2 {
  text-align: center; }

.avisos {
  color: #1e7619;
  font-size: 0.8em;
  font-weight: bolder;
  margin-top: 3%;
  margin-left: 4%; }

.sidebar {
  border-left: none !important; }

.logoico {
  width: 100px;
  position: absolute;
  right: 23px;
  top: -22px; }

.logomodal {
  width: 120px;
  margin-left: 40%;
  margin-bottom: 5%; }

.textoModal {
  text-align: center;
  font-size: 1.1em; }

.container-table thead {
  background-color: #f4f4f4; }

.modal div.modal-container {
  border-radius: 20px;
  width: 620px; }

.modal div.modal-container .modal-body button {
  font-size: 1.1rem !important;
  margin-left: 33% !important;
  width: 200px !important;
  margin-top: 15px; }
