.summary-products {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-flow: row wrap;
  align-items: center;
  min-height: 15rem; }
  .summary-products .product-detail {
    position: relative;
    text-transform: uppercase;
    width: 25%; }
    .summary-products .product-detail img {
      width: 100%; }
    .summary-products .product-detail::after {
      display: block;
      position: relative;
      background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0, #000 100%);
      margin-top: -200px;
      height: 200px;
      width: 100%;
      content: ""; }
    .summary-products .product-detail .product-detail-info {
      z-index: 10;
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      height: 100px; }
      .summary-products .product-detail .product-detail-info h2 {
        margin: 0;
        font-size: 1.6rem;
        line-height: 2rem;
        font-weight: 600;
        letter-spacing: 0.1rem;
        color: white;
        text-align: center; }
      .summary-products .product-detail .product-detail-info .product-detail-subtext {
        color: #a8c94b; }
    .summary-products .product-detail h3 {
      position: absolute;
      top: 200px;
      left: 0;
      width: 100%;
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.1rem;
      color: white;
      text-align: center; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary-products .product-detail .product-detail-info {
    height: 80px; }
    .summary-products .product-detail .product-detail-info h2 {
      font-size: 1.2rem;
      line-height: 1.5rem;
      letter-spacing: 1px; } }
