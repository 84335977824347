.confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 5px;
}

.text-confirm-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.text-confirm-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}
.text-confirm-container h4 {
  color: rgb(27, 151, 27);
  text-align: center;
}
.button-confirm-container {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-confirm-container input {
  border: 0;
  background-color: rgb(25, 163, 71);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-confirm-container input:hover {
  background-color: rgb(19, 191, 76);
}

.confirm-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 480px) {
  .text-confirm-container h4 {
    font-size: 20px;
  }
}
