.summary-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  height: auto;
  padding: 0 14rem; }
  .summary-bar a {
    margin: 2rem 0;
    width: 20%;
    text-align: center;
    text-decoration: none;
    border-left: 2px solid #929496; }
    .summary-bar a:last-child {
      border-right: 2px solid #929496; }

a {
  text-decoration: none; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .summary-bar {
    padding: 0 3rem; } }
