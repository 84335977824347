.image-text-section {
  position: relative; }
  .image-text-section .carousel-title {
    font-size: 1.8rem;
    position: absolute;
    top: 25%;
    margin: 0 0 0 20px;
    padding-left: 30px;
    color: white;
    line-height: 2rem;
    font-weight: 700;
    width: 100%; }
  .image-text-section .legend .sidebar {
    margin: 0;
    width: 100%; }
  .image-text-section .img {
    width: 100%;
    height: auto; }
  .image-text-section .legend.info .carousel-info-title {
    font-size: 2rem;
    line-height: 2rem; }
  .image-text-section .legend.info .carousel-info-details p {
    font-size: 1.1rem;
    line-height: 0.8rem; }
  .image-text-section .legend.info button {
    margin: 0 !important;
    font-size: 1.1rem; }

@media screen and (max-width: 600px) {
  .image-text-section {
    position: relative; }
    .image-text-section .img {
      height: 175px;
      object-position: center;
      object-fit: cover; }
    .image-text-section .carousel-title {
      font-size: 1.4rem;
      position: absolute;
      top: 0;
      margin: 0; }
      .image-text-section .carousel-title .legend.info .carousel-info-title {
        font-size: 2rem;
        line-height: 2.2rem; }
      .image-text-section .carousel-title .legend.info .carousel-info-details p {
        font-size: 1rem;
        line-height: 0.8rem; }
      .image-text-section .carousel-title .legend.info .button-carousel {
        margin: 0 !important;
        margin-top: 1em;
        font-size: 0.8rem;
        background-color: green !important;
        border-color: green !important;
        font-weight: 800; } }
