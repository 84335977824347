.cardd {
  border: none;
  height: 380px;
  background-color: rgb(255, 255, 255);
  margin-top: 120px;
}

.logo {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.logo img {
  position: relative;
}

.input-group input:focus {
}

.password {
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  padding-bottom: 15px;
  width: 100%;
}

.password a {
  text-decoration: none;
  color: rgb(0, 72, 206);
}

.button {
  width: 100%;
  height: 35px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px 5px;
  background-color: rgb(0, 160, 0);
  color: #fff;
}

.button:hover {
  background-color: rgb(0, 175, 0);
}

.bg-login {
  background-image: url(../../../../assets/bgLogin.jpg);
  background-repeat: no-repeat;
  height: 800px;
}
