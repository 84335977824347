.menu .menu-children {
  display: flex;
  align-items: center; }

.menu .icon span.material-icons {
  padding: 0;
  font-size: 14px; }

.menu .red-text .menu-item {
  color: #c22c2b; }

.underline {
  border-bottom: 2px solid rgba(169, 173, 167, 0.3); }
