.screen-contents.services-screen .content-title {
  position: relative; }

.screen-contents.services-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .screen-contents.services-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .screen-contents.services-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

/* Price Table */
.ContainerTable {
  display: flex;
  flex-direction: row;
  width: 450px;
  height: 400px;
  border: 1px solid black; }

.LeftContainer {
  display: flex;
  flex-direction: column;
  width: 35%;
  height: 100%;
  border-right: 1px solid black; }

.M1 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 100%;
  height: 30%;
  color: white;
  border-bottom: 1px solid black;
  background-color: #59ac54; }

.M2 {
  display: flex;
  text-align: center;
  align-items: center;
  font-weight: 600;
  color: #343a40;
  margin-top: 10px;
  width: 100%;
  height: 20%;
  border-bottom: 1px solid black; }

.M3 {
  display: flex;
  text-align: center;
  align-items: center;
  color: #343a40;
  font-weight: 600;
  width: 100%;
  height: 50%; }

.RightContainer {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%; }

.RateField {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 18%;
  color: white;
  font-weight: 600;
  background-color: #59ac54;
  border-bottom: 1px solid black; }

.FromTo {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  width: 100%;
  height: 11.5%; }

.From {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: white;
  font-weight: 600;
  background-color: #59ac54;
  border-right: 1px solid black; }

.To {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  color: white;
  font-weight: 600;
  background-color: #59ac54; }

.Price {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 21.9%;
  border-bottom: 1px solid black; }

.PriceOne {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-right: 1px solid black; }

.PriceTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%; }

.Percent {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 48.8%; }

.PercentOne {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;
  border-right: 1px solid black; }

.PercentTwo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%; }

@media screen and (max-width: 900px) {
  .screen-contents.services-screen {
    margin: 0; }
    .screen-contents.services-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.services-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.services-screen .phone a {
    display: block; }
  .screen-contents.services-screen .phone .hide-on-xs {
    display: none; }
  .ContainerTable {
    width: 95%; } }
