.screen-contents {
  margin-bottom: 10rem; }
  .screen-contents .background img {
    width: 100%; }
  .screen-contents .details-container {
    display: flex;
    justify-content: space-around; }
    .screen-contents .details-container .content {
      padding: 1rem 5rem;
      width: 100%;
      flex: 4; }
      .screen-contents .details-container .content .content-title {
        border-bottom: 2px solid #59ac54; }
        .screen-contents .details-container .content .content-title h1 {
          margin: 0 0 1rem;
          font-weight: 400;
          font-size: 2rem;
          line-height: 2.3rem; }
      .screen-contents .details-container .content .content-description {
        margin: 3rem 0; }
        .screen-contents .details-container .content .content-description p {
          margin: 0;
          font-size: 1.2rem;
          color: #4d4d4f; }
    .screen-contents .details-container .sidebar {
      padding: 1rem 5rem;
      flex: 1; }

.banner-text {
  font-size: 2rem;
  color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents.products-screen {
    margin: 0; }
    .screen-contents.products-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.products-screen .details-container .content-products .content-products-info .product-title {
        align-items: flex-start;
        flex-direction: column; }
        .screen-contents.products-screen .details-container .content-products .content-products-info .product-title .product-slash {
          display: none; }
        .screen-contents.products-screen .details-container .content-products .content-products-info .product-title .product-title-secondary {
          margin: 0; }
      .screen-contents.products-screen .details-container .content-products .image-contents {
        display: none; } }
