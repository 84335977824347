.contact-screen .content-title {
  position: relative; }

.contact-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .contact-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .contact-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

.titel-tex {
  color: #28a745; }

.tex-content p {
  font-size: 20px; }

.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 55px;
  background-color: #c9c9c9;
  border-radius: 15px 10px 0px 0px; }

.formContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px; }

.select {
  width: 100%; }

.formContainer input {
  padding-left: 5px; }

.field {
  display: flex;
  flex-direction: column;
  margin: 1px; }

.fieldNa {
  display: flex;
  flex-direction: row;
  width: 100%; }

.inp {
  width: 90%; }

.inp1 {
  width: 100%; }

.field1 {
  width: 100%; }

.textBox {
  display: flex;
  flex-direction: column;
  width: 100%; }

.textBox textarea {
  font-size: 15px;
  max-width: 100%;
  height: 150px;
  max-height: 150px;
  resize: none;
  padding-left: 5px; }

.fieldRequired {
  font-weight: 100;
  font-size: larger;
  font-style: normal; }

.send {
  display: flex;
  justify-content: flex-end;
  width: 100%; }

.bt {
  width: 19%;
  height: 30px;
  border: none;
  font-weight: 600;
  color: white;
  background: #28a745 !important;
  border-radius: 10px; }

.error {
  font-weight: 600;
  color: red; }

.iframe {
  border: 1px solid #f1f1f2;
  background: white;
  padding: 1rem; }

.contact-form-footer {
  margin-top: 30px;
  font-size: 1.2rem;
  line-height: 1.8rem; }
  .contact-form-footer a {
    color: #59ac54 !important;
    font-weight: bold; }
    .contact-form-footer a:hover {
      text-decoration: underline; }
  .contact-form-footer .form-footer-service {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.8rem; }
    .contact-form-footer .form-footer-service .footer-service-title {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #59ac54; }

@media screen and (max-width: 900px) {
  .bt {
    width: 20%; } }

@media screen and (max-width: 600px) {
  .bt {
    width: 28%; } }

@media screen and (max-width: 900px) {
  .screen-contents.contact-screen {
    margin: 0; }
    .screen-contents.contact-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.contact-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.contact-screen .phone a {
    display: block; }
  .screen-contents.contact-screen .phone .hide-on-xs {
    display: none; }
  .bt {
    width: 35%; } }
