.contact-screen .content-title {
  position: relative; }

.contact-screen .phone {
  position: absolute;
  right: 0;
  top: -10px; }
  .contact-screen .phone a {
    font-size: 1.2rem;
    color: #59ac54; }
    .contact-screen .phone a:hover {
      color: #59ac54;
      text-decoration: none; }

.contact-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  justify-content: center; }

.video-container {
  display: flex;
  align-self: center;
  justify-self: center; }

.button-container {
  display: flex;
  flex-direction: column; }

.contact-button {
  text-decoration: none;
  text-align: center;
  color: black;
  padding: 1px;
  width: 140px;
  border: 3px solid black;
  margin: 10px 0; }
  .contact-button:hover {
    color: black; }

.titel-tex {
  color: #28a745; }

.contact-question {
  color: #468d52;
  font-weight: bolder; }

.tex-content p {
  font-size: 20px; }

.titleContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 220px;
  height: 55px;
  background-color: #c9c9c9;
  border-radius: 15px 10px 0px 0px; }

from {
  width: 100%;
  height: 500px;
  background-color: #28a745; }

.form {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px; }

.formContainer {
  display: flex;
  flex-direction: column; }

.iframe {
  border: 1px solid #f1f1f2;
  background: white;
  padding: 1rem; }

.contact-form-footer {
  margin-top: 30px;
  font-size: 1.2rem;
  line-height: 1.8rem; }
  .contact-form-footer a {
    color: #59ac54 !important;
    font-weight: bold; }
    .contact-form-footer a:hover {
      text-decoration: underline; }
  .contact-form-footer .form-footer-service {
    margin-top: 20px;
    font-size: 1.2rem;
    line-height: 1.8rem; }
    .contact-form-footer .form-footer-service .footer-service-title {
      font-size: 1.4rem;
      line-height: 2rem;
      color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents.contact-screen {
    margin: 0; }
    .screen-contents.contact-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }

@media screen and (max-width: 1200px) {
  .screen-contents.contact-screen .phone {
    position: relative;
    right: 0;
    top: 0; } }

@media screen and (max-width: 400px) {
  .screen-contents.contact-screen .phone a {
    display: block; }
  .screen-contents.contact-screen .phone .hide-on-xs {
    display: none; } }
