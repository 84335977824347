.content-search-bar {
  margin: 2rem 0;
  padding: 0rem;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center; }
  .content-search-bar .search-bar-title {
    color: #59ac54;
    font-size: 1.2rem;
    font-weight: 400; }
  .content-search-bar input {
    display: inline-block;
    width: 25rem;
    height: 2.5rem;
    font-size: 1.2rem;
    padding: 0 0.8rem;
    box-shadow: none;
    border-width: 1; }
  .content-search-bar .button-container {
    width: 200px;
    text-align: center; }
    .content-search-bar .button-container .sk-spinner {
      display: inline-block;
      padding-top: 5px;
      background: red; }
    .content-search-bar .button-container button {
      font-size: 1.2rem; }

.divisor {
  margin: 4rem 0; }

.help-info {
  font-size: 1.1rem; }
  .help-info .link {
    font-size: 1.2rem;
    display: inline-block;
    color: #59ac54; }
  .help-info .img-container {
    text-align: center;
    padding: 5rem; }
    .help-info .img-container img {
      width: 100%; }

.detail-info .title {
  font-size: 1.4rem;
  color: #59ac54; }

.detail-info .deliver {
  display: flex;
  margin-bottom: 20px; }
  .detail-info .deliver .deliver-summary {
    flex: 5;
    border-right: 1px solid #4d4d4f; }
    .detail-info .deliver .deliver-summary .point {
      margin-top: 30px; }
      .detail-info .deliver .deliver-summary .point:first-child {
        margin-top: 0px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-title {
      font-size: 1.2rem;
      font-weight: normal !important;
      color: #4d4d4f !important; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-description {
      font-size: 1rem;
      color: #4d4d4f !important; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-content {
      min-height: 80px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-icon img {
      width: 30px; }
    .detail-info .deliver .deliver-summary .rc-steps .rc-steps-item-tail::after {
      background: transparent !important;
      border: 1px dashed #59ac54; }
  .detail-info .deliver .deliver-metadata {
    flex: 2;
    padding: 0 1rem;
    font-size: 1rem;
    line-height: 1rem;
    margin-bottom: 10px; }
    .detail-info .deliver .deliver-metadata .header-metadata {
      margin: 20px 0 10px;
      font-size: 1.2rem;
      line-height: 1.1rem; }
      .detail-info .deliver .deliver-metadata .header-metadata:first-child {
        margin: 5px 0 10px; }

table {
  border: 0;
  margin: 0;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 400;
  border-collapse: collapse; }
  table tr {
    border-width: 0; }
    table tr th {
      text-align: left;
      background-color: #f1f1f2;
      font-weight: 400;
      border-width: 0; }
    table tr td {
      text-align: left; }

.aditional-info {
  display: flex;
  flex-direction: column;
  font-size: 1.5rem; }

.incident-title {
  color: #59ac54; }

.delivered {
  padding-left: 8px;
  color: #59ac54;
  font-size: 1.4rem; }
  .delivered img {
    width: 14px;
    margin-right: 10px; }

@media screen and (max-width: 900px) {
  .screen-contents.tracking-screen {
    margin: 0; }
    .screen-contents.tracking-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
  .screen-contents .details-container .sidebar {
    display: none; }
  .screen-contents .details-container .content {
    padding: 0; }
  .content-search-bar {
    display: inherit;
    padding: 0px 0px 15px 10px;
    background-color: #ffffff; }
    .content-search-bar input {
      display: inline-block;
      width: 90%;
      height: 2.5rem;
      font-size: 1.2rem;
      margin: -10px 0 15px 0;
      box-shadow: none;
      border-width: 1; }
  .img-container {
    padding: 1rem 0 !important; } }

@media screen and (max-width: 550px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.9rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }

@media screen and (max-width: 470px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.8rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }

@media screen and (max-width: 400px) {
  table {
    border: 0;
    margin: 0;
    width: 100%;
    font-size: 0.7rem;
    font-weight: 400;
    border-collapse: collapse; }
    table tr {
      border-width: 0; }
      table tr th {
        text-align: left;
        background-color: #f1f1f2;
        font-weight: 400;
        border-width: 0; }
      table tr td {
        text-align: left; } }
