.screen-contents-access {
  background: url("../../../../assets/backgrounds/access.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center; }
  .screen-contents-access .access-contents {
    margin: 10rem 0;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 50rem; }
    .screen-contents-access .access-contents .access-title {
      font-size: 1.5rem;
      color: #a8c94b;
      font-weight: 400;
      border-bottom: 2px solid;
      margin: 1rem 2rem; }
    .screen-contents-access .access-contents .access-body {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .screen-contents-access .access-contents .access-body .access-item {
        padding: 0 2rem;
        margin: 0.5rem 0;
        align-items: center;
        display: flex; }
        .screen-contents-access .access-contents .access-body .access-item .access-body-title {
          margin: 0;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: white; }
      .screen-contents-access .access-contents .access-body button {
        margin-left: auto;
        font-size: 1.5rem;
        background: transparent !important; }

@media screen and (min-width: 600px) and (max-width: 900px) {
  .screen-contents-access .access-contents {
    margin: 10rem 10px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 30rem; } }

@media screen and (max-width: 600px) {
  .screen-contents-access .access-contents {
    margin: 10rem 10px;
    background-color: rgba(0, 0, 0, 0.5);
    min-width: 20rem; }
    .screen-contents-access .access-contents .access-body {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .screen-contents-access .access-contents .access-body .access-item {
        padding: 0 2rem;
        margin: 0.5rem 0;
        align-items: center;
        display: flex; }
        .screen-contents-access .access-contents .access-body .access-item .access-body-title {
          margin: 0 10px;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-weight: 400;
          color: white; } }
