.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #383e43;
  color: #929496;
  padding: 1rem 2rem; }
  .footer .content-link {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%; }
    .footer .content-link .links-info {
      margin: 0 0.1rem; }
      .footer .content-link .links-info .title {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 0.5rem 0.1rem 0.5rem 0; }
      .footer .content-link .links-info .links {
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        font-weight: 400; }
  .footer .terms {
    margin: 2rem 0;
    width: 100%;
    padding: 5rem 6rem;
    position: relative; }
    .footer .terms .terms-services {
      border-bottom: 2px solid #4d4d4f; }
      .footer .terms .terms-services .new-phone {
        font-size: 1.3rem; }
      .footer .terms .terms-services a {
        text-decoration: none;
        color: inherit; }
      .footer .terms .terms-services a:not(:last-child):after {
        padding: 0 1rem;
        font-size: 1.5rem;
        content: " | "; }
      .footer .terms .terms-services .terms-title {
        font-size: 1.5rem; }
      .footer .terms .terms-services .terms-title:not(:last-child):after {
        padding: 0 1rem;
        content: " | "; }
      .footer .terms .terms-services .afip-qr-container {
        position: absolute;
        right: 8rem;
        top: 0; }
        .footer .terms .terms-services .afip-qr-container .afip-qr {
          display: inline-block;
          margin-left: 20px; }
          .footer .terms .terms-services .afip-qr-container .afip-qr img {
            height: 100px; }
    .footer .terms .conditions {
      margin: 1rem 0; }
      .footer .terms .conditions p {
        margin: 0;
        font-size: 1rem;
        font-weight: 700; }

.footer-desktop .footer-link {
  text-decoration: none;
  color: #929496;
  font-size: 1rem; }
  .footer-desktop .footer-link span {
    font-size: 1.1rem;
    line-height: 1.3rem; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .terms {
    margin: 1rem 0 !important;
    width: 100%;
    padding: 4rem 0 !important;
    position: relative; }
    .terms .terms-services a:not(:last-child):after {
      font-size: 1.1rem !important; }
    .terms .terms-services .terms-title {
      font-size: 1.1rem !important; }
    .terms .terms-services .afip-qr-container {
      right: 1rem !important;
      top: 0; }
      .terms .terms-services .afip-qr-container .afip-qr {
        display: inline-block;
        margin-left: 10px; }
        .terms .terms-services .afip-qr-container .afip-qr img {
          height: 75px !important; }
    .terms .conditions {
      margin: 1rem 0; }
      .terms .conditions p {
        margin: 0;
        font-size: 1rem;
        font-weight: 700; } }

@media screen and (min-width: 901px) and (max-width: 1100px) {
  .terms {
    padding: 2rem 0 !important; }
    .terms .terms-services .afip-qr-container .afip-qr img {
      height: 50px !important; } }
