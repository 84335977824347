.item {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 1.5rem; }
  .item .item-title {
    font-size: 1.8rem;
    text-align: center;
    line-height: 2rem;
    color: #86b128;
    text-transform: uppercase; }
  .item p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: #252324;
    text-align: center;
    margin: 0 0 1rem 0; }
  .item .link {
    color: #86b128;
    font-size: 1.3rem; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .item .item-title {
    font-size: 1.4rem; }
  .item p {
    font-size: 1.1rem;
    line-height: 1.4rem; }
  .item .link {
    color: #86b128;
    font-size: 1rem; } }
