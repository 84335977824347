.products-container .content-products h1 {
  margin: 0; }

.products-container .content-products .image-contents {
  min-width: 200px;
  max-width: 270px;
  text-align: center;
  padding: 0 20px 50px; }
  .products-container .content-products .image-contents .product-separator {
    position: relative;
    width: 100%;
    padding: 0 50px;
    margin-bottom: 10px;
    margin-top: -10px; }
    .products-container .content-products .image-contents .product-separator div {
      height: 1px;
      background: #c2c2c2; }

.products-container .content-products .inner-content-products {
  margin-left: 2em; }

.products-container .content-products .product-title {
  display: flex;
  align-items: center;
  margin-bottom: 0; }
  .products-container .content-products .product-title .product-title-primary {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #59ac54; }
  .products-container .content-products .product-title .product-title-secondary {
    font-size: 1.4em;
    font-weight: 400;
    color: #a8c94b;
    margin: 0 0.5rem; }

.products-container .content-products .product-detail.margined {
  margin-top: -1rem; }

.products-container .content-products .product-detail p {
  margin: 1rem 0;
  font-size: 1.2rem;
  line-height: 1.8rem; }

.products-container .content-products .product-extra-info {
  margin: 2rem 0; }
  .products-container .content-products .product-extra-info p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324; }
    .products-container .content-products .product-extra-info p span {
      padding: 0 0.5rem 0 0; }
    .products-container .content-products .product-extra-info p .primary {
      font-weight: 700;
      color: #59ac54;
      text-transform: uppercase; }
    .products-container .content-products .product-extra-info p .secondary {
      font-weight: 400;
      color: #a8c94b;
      text-transform: uppercase; }
  .products-container .content-products .product-extra-info .details {
    text-transform: none; }

.products-container .content-products .content-products-picture {
  margin: 0 3rem;
  width: 400px;
  height: 100px;
  border: 2px solid darkgreen; }

.products-container .content-products .extra-link {
  font-size: 2rem;
  color: #59ac54; }

@media screen and (max-width: 900px) {
  .screen-contents {
    margin: 2rem 2rem 10rem 2rem; }
  .products-container {
    margin-top: 0; }
    .products-container .content-products {
      margin-top: 0;
      flex-direction: column; }
      .products-container .content-products .product-title {
        flex-direction: column;
        align-items: flex-start; }
        .products-container .content-products .product-title .product-slash {
          display: none; }
        .products-container .content-products .product-title .product-title-secondary {
          margin: 0; }
      .products-container .content-products .image-contents {
        min-width: 300px;
        padding: 20px;
        text-align: center;
        align-self: center; }
        .products-container .content-products .image-contents .product-separator {
          position: relative;
          width: 100%;
          margin-bottom: 30px;
          margin-top: 0px; }
          .products-container .content-products .image-contents .product-separator div {
            height: 1px;
            background: #c2c2c2; }
    .products-container .product-title {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
      .products-container .product-title .product-title-primary {
        font-size: 1rem;
        text-transform: uppercase;
        color: #59ac54; }
      .products-container .product-title .product-title-secondary {
        font-size: 0.8rem;
        font-weight: 400;
        color: #a8c94b;
        margin: 0 0.5rem; }
    .products-container .product-detail.margined {
      margin-top: -1rem; }
    .products-container .product-detail p {
      margin: 1rem 0;
      font-size: 1.2rem;
      line-height: 1.8rem; } }
