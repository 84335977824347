div.carousel-info .carousel.carousel-slider {
  height: 500px !important; }

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: transparent !important;
  bottom: 60% !important;
  left: 50% !important;
  opacity: 1 !important;
  z-index: 20; }
  .info .carousel-info-title {
    margin: 0;
    font-size: 4rem;
    line-height: 4rem;
    font-weight: 700; }
  .info .carousel-info-details {
    margin: 0;
    text-align: left; }
    .info .carousel-info-details p {
      font-size: 2.4rem;
      line-height: 2rem;
      padding: 2px;
      margin: 10px 0; }
  .info button {
    margin-top: 50px;
    background: transparent !important;
    font-size: 1.5rem; }

.slider-info {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.3); }
