.rezagos-content p {
  padding: 2rem 0;
  font-size: 1.2rem;
  color: #252324;
  font-size: large;
  text-align: justify; }

.content-title h1 {
  color: #468d52 !important; }

@media screen and (max-width: 900px) {
  .screen-contents.rezagos-screen {
    margin: 0; }
    .screen-contents.rezagos-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }
