.agencies-container .agencies-form {
  margin-top: 50px; }
  .agencies-container .agencies-form .agencies-form-title {
    font-size: 1.5rem;
    color: #929496; }
  .agencies-container .agencies-form .label-div {
    display: inline-block; }
  .agencies-container .agencies-form .size-input input {
    padding: 2px 5px;
    font-size: 1rem;
    height: 38px;
    line-height: 1rem;
    width: 100%;
    margin-bottom: 5px; }
  .agencies-container .agencies-form .geo-button div {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px; }
    .agencies-container .agencies-form .geo-button div img {
      height: 20px;
      position: absolute;
      top: 5px;
      left: 0; }
  .agencies-container .agencies-form .address-button-container {
    text-align: right; }
    .agencies-container .agencies-form .address-button-container button {
      font-size: 1.2rem; }

.agencies-container .map-container {
  margin-top: 50px;
  height: 600px; }
  .agencies-container .map-container .info-box {
    padding: 5px 10px;
    background: #fff;
    width: 200px !important; }
    .agencies-container .map-container .info-box p {
      margin: 5px 0; }
      .agencies-container .map-container .info-box p.mb-2 {
        margin-bottom: 10px; }

.agencia {
  width: 48%;
  float: left;
  min-height: 250px; }
  .agencia h3 {
    color: #333333;
    font-family: "Cairo", sans-serif;
    color: #59ac54; }
  .agencia .direccion {
    color: #727272;
    font-weight: bold;
    min-height: 60px;
    margin: 0; }
  .agencia .telefono {
    min-height: 60px;
    margin: 0; }
  .agencia span {
    color: #59ac54; }

.boton_agencias {
  border-color: #59ac54;
  box-shadow: 2px 2px 2px 1px #aaa; }

.boton_ver {
  border-color: #59ac54;
  border-radius: 0%; }

.boton_ver:hover {
  -webkit-box-shadow: 2px 2px 2px 1px #aaa;
  box-shadow: 2px 2px 2px 1px #aaa; }

.boton_agencias:focus {
  outline: none; }

.agencia:nth-child(2n+2) {
  float: right; }

.react-autosuggest__container {
  position: relative;
  display: inline-block;
  min-width: 72%; }

.react-autosuggest__input {
  width: 99%;
  height: 30px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px; }

.react-autosuggest__input:focus {
  outline: none; }

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
  display: none; }

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  width: 99%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 999; }

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px; }

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid #ddd; }

.react-autosuggest__suggestion--focused {
  background-color: #0C7EAF;
  color: #fff; }

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat; }

.highlight {
  color: #59ac54;
  font-weight: bold; }

.react-autosuggest__suggestion--focused .highlight {
  color: #120000; }

@media screen and (max-width: 900px) {
  .screen-contents.agencies-screen {
    margin: 0; }
    .screen-contents.agencies-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.agencies-screen .details-container .agencies-form-title {
        font-size: 1.2rem;
        line-height: 1.5rem; }
  .react-autosuggest__container {
    display: block;
    min-width: 100%; } }
