.recover-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recover-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-recover-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 20px;
}

.title-recover-container h4 {
  color: rgb(27, 151, 27);
}

.text-recover-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.input-recover-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 70%;
  margin-top: 20px;
}
.input-recover-container label {
  margin-left: -35%;
}
.input-recover-container input {
  width: 60%;
  height: 40px;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 10px 10px;
  outline: 0;
}

.button-recover-container {
  display: flex;
  justify-content: flex-end;

  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-recover-container input {
  border: 0;
  background-color: rgb(129, 179, 55);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-recover-container input:hover {
  background-color: rgb(120, 160, 60);
}

.recover-input {
  padding: 0.375rem 0.75rem;
}

.validacion-error p {
  font-size: 18px;
  font-weight: 600;
  color: rgb(255, 0, 0);
}

@media screen and (max-width: 770px) {
  .title-recover-container h4 {
    font-size: 18px;
  }
  .text-recover-container {
    padding: 5px 0;
    height: 92px;
  }
  .text-recover-container p {
    font-size: 14px;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-recover-container label {
    margin-left: 10px;
  }
  .input-recover-container input {
    width: 100%;
  }
  .button-recover-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 320px) {
  .text-recover-container {
    height: 92px;
    margin-top: 25px;
    padding-bottom: 35px;
  }
  .title-recover-container h4 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
  }
  .text-recover-container p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .input-recover-container label {
    margin-left: 10px;
    text-align: center;
  }
  .input-recover-container input {
    width: 100%;
  }
  .button-recover-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
