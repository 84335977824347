.error-screen {
  padding-bottom: 200px; }
  .error-screen .background {
    padding: 50px 0;
    text-align: center; }
    .error-screen .background img {
      width: 100%;
      max-width: 500px; }
  .error-screen .details-container {
    padding: 0 20px; }
    .error-screen .details-container p {
      text-align: center; }
      .error-screen .details-container p.error {
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #59ac54; }
