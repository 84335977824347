.table-paradas {
  height: 280px;
  overflow-y: scroll; }

table.unstyledTable {
  width: 500px; }

table.unstyledTable td,
table.unstyledTable th {
  border: 0px solid #aaaaaa; }

table.unstyledTable tbody td {
  font-size: 13px; }

table.unstyledTable thead {
  background: #dddddd;
  border-bottom: 1px solid #7e7e7e; }

table.unstyledTable thead th {
  font-size: 13px;
  font-weight: bold; }

@media screen and (max-width: 900px) {
  .table.unstyledTable {
    width: 300px; } }

.dropdown-toggle {
  width: 100%;
  text-align: left; }

.dd button {
  background-color: #ffffff !important;
  color: #000 !important;
  border: 1px solid #d2d2d2 !important; }
