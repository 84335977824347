.terms-content p {
  padding: 2rem 0;
  font-size: 1.2rem;
  color: #252324;
  font-size: large;
  text-align: justify; }

@media screen and (max-width: 900px) {
  .screen-contents.terms-screen {
    margin: 0; }
    .screen-contents.terms-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }
