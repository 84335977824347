.services-section {
  background-color: white;
  position: relative;
  width: 100%;
  padding-bottom: 20px; }
  .services-section .sidebar-title {
    color: #252324;
    border-bottom: 2px solid #59ac54;
    margin: 0 20px 0 20px; }
    .services-section .sidebar-title h1 {
      margin: 0 0 10px 20px;
      font-weight: 400;
      font-size: 2rem;
      text-align: left; }
  .services-section .side-item {
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 20px 0 20px;
    flex-direction: column;
    position: relative; }
    .services-section .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .services-section .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .services-section .side-item form {
      width: 100%; }
      .services-section .side-item form input {
        width: 100%; }
  .services-section .side-title {
    margin-right: 0;
    margin-left: 0;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 0;
    margin-left: 0;
    font-size: 1.5rem;
    color: #59ac54; }
  .services-section .side-img {
    width: 40px;
    height: auto; }
    .services-section .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; }
  .services-section .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    margin-bottom: 10px;
    border: 1px solid rgba(146, 148, 150, 0.3); }

@media screen and (min-width: 460px) and (max-width: 520px) {
  .services-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.5rem;
    color: #59ac54; }
  .side-img {
    width: 30px;
    height: auto; }
    .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; } }

@media screen and (max-width: 460px) {
  .services-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    line-height: 1.2rem;
    font-size: 1rem;
    font-weight: 600;
    position: relative;
    color: #929496; }
  .services-section .side-green-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.2rem;
    color: #59ac54; }
  .side-img {
    width: 30px;
    height: auto; }
    .side-img.arrow {
      margin-left: auto;
      position: absolute;
      right: 0; } }
