.match-detail .match-title {
  cursor: pointer;
  text-transform: uppercase;
  color: #59ac54;
  font-size: 1.2rem;
  font-weight: 400;
  position: relative; }
  .match-detail .match-title i {
    position: absolute;
    font-size: 30px;
    margin-top: 4px;
    margin-left: 10px; }
