.category-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: auto;

  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 30px 1px rgb(0 0 0 / 82%);
}

.title-category-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;

  margin-top: 40px;
}

.title-category-container h4 {
  color: rgb(27, 151, 27);
}

.text-category-container {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 70%;
  height: 55px;
  padding-top: 5px;
  border-bottom: 1px solid greenyellow;
}

.text-category-container2 {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 10px;
}

.input-category-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 70%;
  margin-bottom: 10px;
}

.input-category-container input {
  width: 30%;
  height: 40px;
  border: none;
  border-radius: 10px 10px;
  background-color: rgb(135, 202, 35);
  color: white;
  font-weight: 600;
  margin-right: 12px;
  transition: 0.3s;
}

.input-category-container input:hover {
  background-color: rgb(149, 235, 21);
}
.input-category-checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input-category-checkbox-container input {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-bottom: 13px;
}

.button-category-container {
  display: flex;
  justify-content: flex-end;

  width: 40%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button-category-container input {
  border: 0;
  background-color: rgb(25, 163, 71);
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;

  width: 120px;
  height: 40px;

  transition: 0.3s;
}

.button-category-container input:hover {
  background-color: rgb(19, 191, 76);
}

.category-input {
  padding: 0.375rem 0.75rem;
}

@media screen and (max-width: 995px) {
  .title-category-container h4 {
    font-size: 20px;
  }
  .sub-title-category-container h5 {
    font-size: 18px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 16px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 769px) {
  .title-category-container h4 {
    font-size: 20px;
  }
  .sub-title-category-container {
    width: 70%;
  }
  .sub-title-category-container h5 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 25px;
  }
  .text-category-container {
    width: 70%;
    padding-bottom: 10px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 25px;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 14px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 595px) {
  .title-category-container h4 {
    font-size: 18px;
    text-align: center;
  }
  .sub-title-category-container {
    width: 70%;
  }
  .sub-title-category-container h5 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 45px;
  }
  .text-category-container {
    width: 70%;
    padding-bottom: 10px;
  }
  .text-category-container p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 35px;
  }
  .input-category-container {
    display: flex;
    flex-direction: column;
  }
  .input-category-container input {
    width: 120px;
    height: 40px;
    margin: 5px 0px;

    text-align: center;
  }

  .text-category-container2 {
    width: 70%;
  }
  .text-category-container2 p {
    text-align: center;
  }
  .input-category-checkbox-container {
    width: 70%;
  }

  .input-category-checkbox-container p {
    font-size: 14px;
  }

  .button-category-container {
    align-items: center;
    justify-content: center;
  }
}
