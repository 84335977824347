.tabbar-container {
  margin-top: 50px;
  width: 100%;
  border-bottom: 1px solid #c2c2c2; }
  .tabbar-container .tabbar-option {
    display: inline-block;
    position: relative;
    top: 1px;
    font-size: 1.2rem;
    border: 1px solid #c2c2c2;
    background: #c2c2c2;
    border-radius: 5px 5px 0 0;
    padding: 5px 30px;
    cursor: pointer;
    margin-right: 5px; }
    .tabbar-container .tabbar-option.active {
      border-bottom: 1px solid #59ac54;
      background: #6db568 !important; }
