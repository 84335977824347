button {
  margin: 0 10px 10px 0;
  padding: 0 2rem;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  width: auto;
  white-space: nowrap;
  border-radius: 35px;
  border: solid; }

.primary-button {
  background: black !important;
  color: white; }

.secondary-button {
  background: transparent;
  color: white;
  border-color: #a8c94b; }

.sm {
  font-size: 1rem; }

.xmd {
  font-size: 1.5rem; }

.md {
  font-size: 2rem; }

.lg {
  font-size: 3rem; }
