div.menu-item {
  display: flex;
  align-items: center; }
  div.menu-item span {
    cursor: pointer;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    padding: 0.3rem 1rem; }
    div.menu-item span.menu-item {
      font-size: 1.1rem;
      font-weight: 600;
      color: #929496;
      text-transform: uppercase; }
    div.menu-item span.menu-item-lower {
      font-size: 1.1rem;
      font-weight: 600;
      color: #929496; }
    div.menu-item span.secondary-menu-item {
      font-size: 1.2rem;
      color: #59ac54;
      text-decoration: none; }
