.quick-access-section {
  background-color: white;
  position: relative;
  width: 100%;
  padding-bottom: 20px; }
  .quick-access-section .sidebar-title {
    color: #252324;
    border-bottom: 2px solid #59ac54;
    margin: 0 20px 0 20px; }
    .quick-access-section .sidebar-title h1 {
      margin: 0 0 10px 20px;
      font-weight: 400;
      font-size: 2rem;
      text-align: left; }
  .quick-access-section .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 20px 0 20px;
    position: relative; }
    .quick-access-section .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .quick-access-section .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .quick-access-section .side-item form {
      width: 100%; }
      .quick-access-section .side-item form input {
        width: 100%; }
  .quick-access-section .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.3rem;
    color: #929496;
    font-weight: 600; }
  .quick-access-section .side-img {
    width: 40px;
    height: auto; }
    .quick-access-section .side-img.arrow {
      margin-left: auto; }
  .quick-access-section .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    border: 1px solid rgba(146, 148, 150, 0.3);
    padding-left: 1rem; }
