.form-container {
  font-size: 1rem;
  color: #4d4d4f;
  margin-top: 2rem; }
  .form-container input {
    font-size: 1rem;
    height: 2rem; }

.informationForm {
  font-size: 1.2rem;
  margin-top: 2rem; }

.inputForm {
  color: #252324;
  font-size: 1.5rem;
  font-weight: 400;
  height: 3rem; }

.messageForm {
  color: #252324;
  font-size: 1rem;
  font-weight: 400;
  height: 14rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem; }

.labelForm {
  height: auto;
  display: grid;
  margin-top: 0.6rem; }

.sendForm {
  display: flex;
  justify-content: flex-end; }
  .sendForm button {
    font-size: 1.2rem;
    width: 150px; }

@media screen and (max-width: 900px) {
  .informationForm {
    font-size: 1rem;
    margin-top: 0.8rem;
    margin-bottom: 0.5rem; }
  .sendForm {
    display: flex;
    justify-content: center; }
    .sendForm button {
      font-size: 1.2rem;
      width: 150px; } }
