.item-summary {
  margin-top: 1rem; }
  .item-summary .icon-image {
    height: 7rem; }
  .item-summary .summary-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1e8d34;
    letter-spacing: 0.1rem;
    margin: 0;
    padding: 0;
    text-align: center; }

.likes__list {
  position: absolute;
  box-sizing: border-box;
  max-width: 30%;
  z-index: 999;
  background: #1e8d34;
  padding: 5px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: snow; }
