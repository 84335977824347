.error {
  display: block !important; }

.active {
  color: white !important;
  background-color: #6db568 !important; }

.btn {
  border: 1px solid #6db568 !important; }

.btn-primary {
  border: 1px solid #6db568 !important;
  background-color: #6db568 !important;
  color: white !important; }

.btn-secondary {
  border: 1px solid gray !important;
  background-color: gray !important;
  color: white !important; }

.card-header {
  background-color: #6db568 !important;
  color: white !important;
  cursor: pointer; }

.screen-contents .details-container .content .content-title {
  border-bottom: 2px solid #6db568;
  line-height: 4rem; }

.ask-box {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  flex-direction: column; }
  .ask-box .ask-title {
    margin: 0;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    color: #59ac54; }
  .ask-box .ask-body {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: #252324; }
  .ask-box .ask-link {
    color: #59ac54;
    font-size: 1.2rem;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.1rem; }

@media screen and (max-width: 900px) {
  .screen-contents .details-container .content .content-title {
    border-bottom: 2px solid #6db568;
    padding-bottom: 10px;
    line-height: 3rem; }
  .screen-contents.faqs-screen {
    margin: 0; }
    .screen-contents.faqs-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; } }
