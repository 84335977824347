.service-item {
  margin: 4rem 0; }
  .service-item .item-title {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: #59ac54;
    letter-spacing: 0.1rem; }
  .service-item .item-details {
    margin: 0;
    color: #252324;
    font-size: 1.2rem;
    line-height: 2rem; }

@media screen and (max-width: 900px) {
  .service-item {
    margin: 2rem 0; } }
