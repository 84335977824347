.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.titulo-formulario {
  margin-bottom: 20px;
}

.titulo-formulario h3 {
  width: 90%;
  color: rgb(0, 156, 0);
  margin-bottom: 10px;
  margin-left: 40px;
  border-bottom: 1px solid rgb(0, 156, 0);
}
.formulario {
  color: black;
  display: flex;
  flex-direction: column;
  padding: 15px 15px;
  width: 100%;
  height: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 20px 30px 1px rgb(0 0 0 / 12%);
  border-radius: 15px 15px;
}
.campo {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.campoNa {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.campoNa input {
  height: 40px;
  border-radius: 10px 10px;
  border: 1px solid gray;
  outline: 0px;
}

.campoNa .input-apellido {
  width: 100%;
}

.input-nombre {
  width: 95%;
}

.campoNa label {
  /* margin-bottom: -20px;*/
  margin-left: 10px;
  margin-top: 5px;
}

.campoNa p {
  color: rgb(0, 156, 0);
  margin-top: 5px;
  margin-left: 10px;
}

.documento {
  cursor: pointer;
  width: 95%;
  height: 40px;
  border-radius: 10px 10px;
}

.input-direccion {
  width: 80%;
}

.recaptcha-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.terminos-condiciones {
  display: flex;
  flex-direction: row;
}

.checkBox {
  width: 15px;
  height: 15px;
  margin-right: 10px;
  cursor: pointer;
}

.boton-crear-cuenta {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.boton-crear-cuenta input {
  height: 40px;
  border: none;
  background-color: #59ac54;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px 8px;
  margin-right: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
}

.boton-crear-cuenta input:hover {
  background-color: #478344;
}

.validacion-error p {
  color: red;
  font-size: 14px;
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .titulo-formulario {
    width: 100%;
  }

  .titulo-formulario h3 {
    text-align: center;
    margin-bottom: 10px;
  }

  .campo {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .campoNa input {
    width: 100%;
  }
  .campoNa .input-apellido {
    width: 90%;
  }
}

@media screen and (max-width: 380px) {
  .containerFormm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .formulario {
    width: 90%;
  }
  .campo-select {
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .campo-select .select-campo select {
    /*width: 150px;*/
    width: auto;
    min-width: auto;
  }
}
