.section-button {
  margin: 0;
  height: 100px;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  position: relative;
  align-content: center; }
  .section-button p {
    font-size: 2rem;
    width: 70%;
    line-height: 100px;
    margin: 0;
    color: #ffffff;
    font-weight: 600;
    position: relative;
    display: inline-block; }
  .section-button .side-img {
    width: 40px;
    height: auto; }
    .section-button .side-img.arrow {
      position: absolute;
      top: 35px;
      right: 20px;
      width: 30px; }
  .section-button .img {
    height: 70px;
    width: 70px;
    position: absolute;
    top: 15px;
    left: 30px; }

@media screen and (max-width: 470px) {
  .section-button {
    margin: 0;
    height: 100px;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    align-content: center; }
    .section-button p {
      font-size: 1.7rem;
      width: 70%;
      line-height: 100px;
      margin: 0;
      color: #ffffff;
      font-weight: 600;
      position: relative;
      display: inline-block; }
    .section-button .side-img {
      width: 40px;
      height: auto; }
      .section-button .side-img.arrow {
        position: absolute;
        top: 35px;
        right: 10px; }
    .section-button .img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 25px;
      left: 30px; } }

@media screen and (max-width: 400px) {
  .section-button {
    margin: 0;
    height: 100px;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    align-content: center; }
    .section-button p {
      font-size: 1.5rem;
      width: 70%;
      line-height: 100px;
      margin: 0;
      color: #ffffff;
      font-weight: 600;
      position: relative;
      display: inline-block; }
    .section-button .side-img {
      width: 40px;
      height: auto; }
      .section-button .side-img.arrow {
        position: absolute;
        top: 35px;
        right: 10px; }
    .section-button .img {
      height: 50px;
      width: 50px;
      position: absolute;
      top: 25px;
      left: 15px; } }
