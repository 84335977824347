.content-products h1 {
  margin: 0; }

.content-products .image-contents {
  min-width: 200px;
  max-width: 270px;
  padding: 8px 14px;
  text-align: center; }
  .content-products .image-contents .icon-img {
    width: 100%; }
    .content-products .image-contents .icon-img.second-img {
      margin-top: 50px;
      width: 150px; }

.content-products .product-title {
  display: flex;
  align-items: center; }
  .content-products .product-title .product-title-primary {
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: #59ac54; }
  .content-products .product-title .product-title-secondary {
    font-size: 1.4em;
    line-height: 1.8rem;
    font-weight: 400;
    color: #a8c94b;
    margin: 0 0.5rem; }

.content-products .product-detail p {
  font-size: 1.2rem;
  line-height: 1.8rem; }

.content-products .product-extra-info {
  margin: 2rem 0;
  text-transform: uppercase; }
  .content-products .product-extra-info p {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324; }
    .content-products .product-extra-info p span {
      padding: 0 0.5rem 0 0; }
    .content-products .product-extra-info p .primary {
      font-weight: 700;
      color: #59ac54; }
    .content-products .product-extra-info p .secondary {
      font-weight: 400;
      color: #a8c94b; }

.content-products .content-products-picture {
  margin: 0 3rem;
  width: 400px;
  height: 100px;
  border: 2px solid darkgreen; }

.content-products .extra-link {
  font-size: 2rem;
  color: #59ac54; }
