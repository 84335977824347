.home-agencies {
  position: relative; }
  .home-agencies .map-container {
    height: 400px; }
  .home-agencies .agencies-form {
    background: white;
    position: absolute;
    top: 60px;
    left: 60px;
    width: 600px;
    z-index: 100; }
    .home-agencies .agencies-form .agencies-title {
      padding: 10px 0 0 20px;
      border-bottom: 1px solid #c2c2c2; }
      .home-agencies .agencies-form .agencies-title h2 {
        margin: 0; }
    .home-agencies .agencies-form .agencies-form-title {
      padding: 10px 20px 0;
      font-size: 0.8rem; }
    .home-agencies .agencies-form .agencies-form-input {
      padding: 10px 20px; }
      .home-agencies .agencies-form .agencies-form-input .geo-input input {
        font-size: 1.1rem;
        width: 100%; }
      .home-agencies .agencies-form .agencies-form-input .label-div {
        color: #59ac54;
        font-size: 0.8rem; }
    .home-agencies .agencies-form .geo-button {
      padding: 0 20px 0;
      font-size: 0.8rem;
      cursor: pointer; }
      .home-agencies .agencies-form .geo-button img {
        width: 20px; }
    .home-agencies .agencies-form .address-button-container {
      padding: 0 20px 10px;
      text-align: right; }
      .home-agencies .agencies-form .address-button-container button {
        font-size: 0.8rem; }

.info-box {
  padding: 5px 10px;
  background: #fff;
  width: 200px !important; }
  .info-box h2 {
    color: #59ac54; }
  .info-box p {
    margin: 5px 0; }
    .info-box p.mb-2 {
      margin-bottom: 10px; }

@media screen and (max-width: 1300px) {
  .home-agencies .agencies-form {
    top: 60px;
    left: 20px;
    width: 400px; } }

.geosuggest__suggests--hidden {
  display: none; }

.geosuggest {
  position: relative; }
  .geosuggest .geosuggest__suggests-wrapper {
    z-index: 200;
    position: absolute;
    background: white;
    width: 100%; }
    .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
      border: 1px solid #c2c2c2;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0; }
      .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
        padding: 3px 10px;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer; }
        .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover {
          background: #f1f1f2; }
