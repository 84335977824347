.spinner {
  margin-left: calc(50% - 10px);
  margin-top: 18% !important; }

.fundacion-quote {
  margin: 4rem 0; }
  .fundacion-quote .quote-title {
    margin: 0;
    font-size: 1.8rem;
    line-height: 2rem;
    color: #252324;
    font-weight: 400; }
  .fundacion-quote .quote-description {
    margin: 0;
    font-size: 1.2rem;
    line-height: 2rem;
    color: #252324; }
  .fundacion-quote .quote-sign {
    margin: 2rem 0;
    width: 400px;
    height: 150px;
    margin-left: auto; }
    .fundacion-quote .quote-sign img {
      width: 100%; }
  .fundacion-quote .quote-owner {
    text-align: right;
    font-weight: bold; }

.news-item {
  display: flex;
  margin-bottom: 20px; }
  .news-item.bordered {
    border-top: 1px solid #c2c2c2;
    padding-top: 30px; }
  .news-item .media-container {
    flex: 1;
    cursor: pointer;
    position: relative; }
    .news-item .media-container img {
      width: 100%; }
    .news-item .media-container .play-button {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center; }
      .news-item .media-container .play-button img {
        width: 80px; }
  .news-item .text-container {
    flex: 4;
    padding: 0 20px; }
    .news-item .text-container h2 {
      color: #59ac54;
      font-size: 1.4rem;
      margin: 10px 0 0; }
    .news-item .text-container .news-text {
      font-size: 1rem; }

@media screen and (max-width: 1300px) {
  .news-item .media-container {
    flex: 2; }
    .news-item .media-container .play-button img {
      width: 40px; } }

@media screen and (max-width: 900px) {
  .fundacion-quote {
    margin: 2rem 0; }
    .fundacion-quote .quote-title {
      margin: 0;
      font-size: 1.8rem;
      line-height: 2rem;
      color: #252324;
      font-weight: 400; }
    .fundacion-quote .quote-description {
      margin: 0;
      font-size: 1.2rem;
      line-height: 2rem;
      color: #252324; }
    .fundacion-quote .quote-sign {
      margin: 2rem 0 30px 0;
      width: 300px;
      height: 120px;
      margin-left: auto; }
      .fundacion-quote .quote-sign img {
        width: 100%; }
  .news-item {
    flex-direction: column; }
    .news-item .media-container .play-button img {
      width: 30%; } }
