.pointer-link {
  cursor: pointer; }

.sidebar {
  margin: 0 0 2rem;
  border-left: 1px solid #929496; }
  .sidebar.floating-sidebar {
    padding: 1rem 2rem !important; }
  .sidebar .sidebar-title {
    border-bottom: 2px solid #59ac54; }
    .sidebar .sidebar-title h1 {
      margin: 0;
      font-weight: 400;
      font-size: 1.5rem; }
  .sidebar .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0; }
    .sidebar .side-item a {
      margin-left: auto; }
    .sidebar .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
  .sidebar .side-title {
    margin-right: 1rem;
    margin-left: 1rem;
    font-size: 1.15rem;
    color: #252324; }
  .sidebar .side-img {
    width: 45px;
    height: auto; }
    .sidebar .side-img.arrow {
      margin-left: auto; }
  .sidebar .tracking-input {
    font-size: 1rem;
    width: 100%;
    min-height: 2rem; }
  .sidebar .dropcl {
    cursor: pointer; }
  .sidebar img.image-banner {
    width: 100%;
    margin-top: 20px; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .sidebar .side-title {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    font-size: 1.1rem;
    width: 115px; } }
