.map-container {
  background: #383e43; }
  .map-container img {
    width: 100%; }

.go-down {
  top: 60vh;
  position: absolute;
  text-align: center;
  animation: bounce infinite 2s;
  left: calc(50% - 25px);
  cursor: pointer; }

@keyframes bounce {
  0%,
  20%,
  80%,
  100% {
    transform: translateY(0); }
  50% {
    transform: translateY(15px); }
  /*60% {transform: translateY(15px);}*/ }
