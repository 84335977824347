.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #383e43;
  color: #929496;
  padding: 1rem 2rem;
  margin-top: -10px; }
  .footer .content-link {
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%; }
    .footer .content-link .links-info {
      margin: 0 0.1rem 1rem 0;
      width: 100%; }
      .footer .content-link .links-info .afip-qr-container {
        position: absolute;
        right: 2rem;
        top: 0; }
        .footer .content-link .links-info .afip-qr-container .afip-qr {
          display: inline-block;
          margin-left: 20px;
          margin-top: 20px; }
          .footer .content-link .links-info .afip-qr-container .afip-qr img {
            height: 100px; }
      .footer .content-link .links-info .title {
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0.5rem 0.1rem 0.5rem 0; }
      .footer .content-link .links-info .links {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-weight: 600; }
  .footer .terms {
    margin: 2rem 0 30px 0;
    width: 100%;
    padding: 5rem 6rem 3rem 0;
    position: relative; }
    .footer .terms p {
      margin: 20px 0 0 0;
      font-size: 18px;
      font-weight: 600; }
    .footer .terms .terms-services-mobile {
      flex-direction: column; }
      .footer .terms .terms-services-mobile .new-phone {
        color: #929496; }
        .footer .terms .terms-services-mobile .new-phone a {
          color: #929496; }
      .footer .terms .terms-services-mobile .terms-title {
        font-size: 18px;
        font-weight: 600;
        line-height: 0.5rem;
        text-decoration: none;
        text-align: left;
        color: #929496; }

.footer-link {
  text-decoration: none;
  text-align: left;
  font-size: 20px;
  color: #929496; }

@media screen and (max-width: 400px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    background-color: #383e43;
    color: #929496;
    padding: 1rem 2rem;
    min-height: 298px;
    margin-top: -2px; }
    .footer .content-link {
      display: flex;
      position: relative;
      justify-content: space-around;
      align-items: flex-start;
      width: 100%; }
      .footer .content-link .links-info {
        margin: 0 2rem 1rem 0;
        width: 100%; }
        .footer .content-link .links-info .afip-qr-container {
          position: absolute;
          right: 0rem;
          top: 145px; }
          .footer .content-link .links-info .afip-qr-container .afip-qr {
            display: inline-block;
            margin-left: 20px;
            margin-top: 20px; }
            .footer .content-link .links-info .afip-qr-container .afip-qr img {
              height: 75px; }
        .footer .content-link .links-info .title {
          font-size: 1.4rem;
          font-weight: 600;
          margin: 0.5rem 0.5rem 0.5rem 0; }
        .footer .content-link .links-info .links {
          display: flex;
          flex-direction: column;
          text-align: left;
          font-weight: 600; }
    .footer .terms {
      margin: 2rem 0 30px 0;
      width: 100%;
      padding: 5rem 6rem 3rem 0;
      position: relative; }
      .footer .terms p {
        margin: 81px 0 0 0;
        font-size: 18px;
        font-weight: 600; }
      .footer .terms .terms-services-mobile {
        flex-direction: column; }
        .footer .terms .terms-services-mobile .terms-title {
          font-size: 17px;
          font-weight: 600;
          line-height: 0.5rem;
          text-decoration: none;
          text-align: left;
          color: #929496; } }
