.steps-for-tips {
  margin-top: 50px;
  padding: 2rem 0;
  display: flex;
  justify-content: space-around;
  align-items: center; }
  .steps-for-tips .step-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 2rem; }
    .steps-for-tips .step-item .step-number {
      font-size: 2rem;
      line-height: 1rem;
      color: #a8c94b;
      align-self: flex-start;
      font-weight: 400;
      margin: 0; }
    .steps-for-tips .step-item .step-icon .step-img {
      height: 10rem; }
    .steps-for-tips .step-item .step-description {
      text-align: center;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: #252324; }

.tips-suggestion h6 {
  color: #59ac54;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 400; }

.separator-suggestion {
  border: 1px solid #929496;
  margin: 2rem 0; }

.pack-suggestion {
  margin: 2rem 0; }
  .pack-suggestion .pack-title {
    font-size: 1.6rem;
    line-height: 2.2rem;
    color: #252324;
    font-weight: 600;
    margin: 0; }
  .pack-suggestion .pack-description {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: #252324;
    font-weight: 400; }

.embalaje-suggestion {
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: #252324;
  margin: 1rem 0; }
  .embalaje-suggestion .embalaje-suggestion-title {
    font-weight: normal;
    margin: 0; }
  .embalaje-suggestion .embalaje-suggestion-description {
    margin: 0; }

select {
  min-height: 1rem;
  min-width: 20rem;
  color: #252324;
  font-size: 1rem;
  font-weight: 400; }

@media screen and (max-width: 900px) {
  .screen-contents.tips-screen {
    margin: 0; }
    .screen-contents.tips-screen .details-container {
      margin: 2rem 2rem 10rem 2rem; }
      .screen-contents.tips-screen .details-container .steps-for-tips {
        flex-direction: column;
        padding: 0 0 2rem; }
        .screen-contents.tips-screen .details-container .steps-for-tips .step-item {
          margin: 1rem; }
          .screen-contents.tips-screen .details-container .steps-for-tips .step-item img {
            height: 7rem; } }
