.logo-via-compras {
  width: 170px; }

.header-gradient {
  min-height: 3rem;
  width: 100%;
  background: -webkit-linear-gradient(left, #008a00, #00a100, #30c90e);
  background: -moz-linear-gradient(left, #008a00, #00a100, #30c90e);
  background: -o-linear-gradient(left, #008a00, #00a100, #30c90e);
  background: linear-gradient(left, #008a00, #00a100, #30c90e); }

.header {
  width: 100%;
  align-items: center;
  margin: 0.2rem 0;
  position: relative; }
  .header .logo {
    padding: 1rem 0 1rem 5rem;
    display: inline-block; }
    .header .logo img {
      width: 320px; }
  .header .menu-container {
    display: inline-block;
    position: absolute;
    right: 5rem;
    top: 15px; }

.menu-separator {
  color: grey; }

.menu-link {
  display: flex;
  align-items: center; }

.hamburger-menu {
  display: none; }
  .hamburger-menu .hamburgerIcon {
    cursor: pointer;
    width: 30px;
    height: 30px; }

.spinner {
  margin-top: 10px;
  height: 20px;
  line-height: 30px; }

.modal {
  opacity: 1;
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: opacity 1s ease-in;
  pointer-events: auto;
  overflow-y: auto; }
  .modal div.modal-container {
    opacity: 1;
    width: 700px;
    min-height: 200px;
    margin: 10% auto;
    position: relative;
    padding: 30px;
    background: #f1f1f2; }
    @media screen and (max-width: 900px) {
      .modal div.modal-container {
        width: 100%; } }
    .modal div.modal-container .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c2c2c2;
      cursor: pointer;
      font-size: 14px;
      border-radius: 30px;
      font-weight: bold;
      color: white;
      height: 30px;
      width: 30px;
      padding: 3px 0 0 3px; }
      .modal div.modal-container .close-button:hover {
        background: #929496; }
    .modal div.modal-container .modal-body p {
      font-size: 1.2rem;
      line-height: 1rem; }
    .modal div.modal-container .modal-body button {
      font-size: 1.2rem; }
    .modal div.modal-container .modal-body .modal-img {
      width: 95%;
      margin-top: 15px;
      margin-left: 2.5%; }

.modal-popup {
  opacity: 1;
  display: block;
  visibility: visible;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  transition: opacity 1s ease-in;
  pointer-events: auto;
  /*overflow-y: auto;*/
  overflow: auto; }
  .modal-popup div.modal-container-popup {
    opacity: 1;
    width: fit-content;
    min-height: 200px;
    margin: 0 auto;
    position: relative;
    padding: 15px;
    /*background: #f1f1f2;*/ }
    @media screen and (max-width: 900px) {
      .modal-popup div.modal-container-popup {
        width: fit-content; } }
    .modal-popup div.modal-container-popup .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #c2c2c2;
      cursor: pointer;
      font-size: 14px;
      border-radius: 30px;
      font-weight: bold;
      color: white;
      height: 30px;
      width: 30px;
      padding: 3px 0 0 3px; }
      .modal-popup div.modal-container-popup .close-button:hover {
        background: #929496; }
    .modal-popup div.modal-container-popup .modal-body p {
      font-size: 1.2rem;
      line-height: 1rem; }
    .modal-popup div.modal-container-popup .modal-body button {
      font-size: 1.2rem; }
    .modal-popup div.modal-container-popup .modal-body .modal-img {
      width: 95%;
      margin-top: 15px;
      margin-left: 2.5%; }

.carousel-img {
  object-fit: cover;
  object-position: right; }

.credits {
  padding: 10px 0;
  text-align: center;
  background: #181c1e;
  color: white; }
  .credits a {
    color: white; }
    .credits a:hover {
      text-decoration: none;
      font-weight: bold; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .header .logo {
    padding: 1rem 0 1rem 2rem; }
    .header .logo img {
      width: 260px; }
  .header .menu-container {
    right: 2rem;
    top: 5px; } }

@media screen and (max-width: 900px) {
  .hamburger-menu {
    display: inline; }
    .hamburger-menu .floating-menu {
      position: absolute;
      top: 65px;
      left: 0;
      width: 100%;
      background: #008a00;
      z-index: 100; }
      .hamburger-menu .floating-menu .fm-item {
        padding: 5px 15px;
        border-bottom: 1px solid white;
        color: white; }
        .hamburger-menu .floating-menu .fm-item a {
          font-weight: bold;
          color: white !important; }
          .hamburger-menu .floating-menu .fm-item a:visited {
            color: white !important; }
          .hamburger-menu .floating-menu .fm-item a div {
            position: relative; }
            .hamburger-menu .floating-menu .fm-item a div .chevron {
              position: absolute;
              right: 20px;
              top: -5px;
              font-size: 20px; }
        .hamburger-menu .floating-menu .fm-item.top-border {
          border-top: 1px solid white; }
    .hamburger-menu .floating-menu-overlay {
      z-index: 50;
      background: rgba(40, 40, 40, 0.5);
      position: absolute;
      top: 82px;
      width: 100%;
      left: 0; }
  .menu-container {
    display: none !important; }
  .header .logo {
    flex: inherit;
    padding: 0rem 0rem 0rem 1rem;
    width: 90%; }
  .header .logo img {
    width: 200px; }
  .header-gradient {
    display: none; }
  .legend .carousel-content {
    display: none; }
  .carousel .slide .legend {
    position: inherit;
    display: block;
    width: 100% !important;
    position: inherit;
    margin-left: 0;
    padding: 0; }
  .legend .sidebar {
    margin: 0;
    width: 100%; }
  .carousel.carousel-slider {
    height: 100%; }
  .summary-bar {
    display: none; }
  .footer .terms {
    margin: 0;
    padding: 0; } }

@media screen and (max-width: 400px) {
  .header .logo {
    flex: inherit;
    padding: 0rem 0rem 0rem 1rem;
    width: 85%; } }

@media screen and (min-width: 1920px) {
  .details-container {
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto; } }
