.carousel.carousel-slider {
  height: calc(100vh - 390px);
  overflow: unset !important; }
  .carousel.carousel-slider .slider-wrapper {
    height: 100%; }
    .carousel.carousel-slider .slider-wrapper ul {
      height: 100%; }

.carousel .control-dots {
  bottom: -2em !important; }

.carousel .control-dots .dot {
  background: #86b128; }

.button {
  border: #86b128 !important; }

.button-carousel {
  border: #86b128 !important; }

ul.control-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; }

.slide-div-container {
  height: 100%; }
  .slide-div-container img {
    height: 100%; }

.legend {
  display: flex;
  vertical-align: center;
  background: transparent !important;
  top: calc(10% - 55px);
  bottom: initial !important;
  left: 49% !important;
  opacity: 1 !important;
  width: 100% !important; }
  .legend .carousel-content {
    margin: 3rem 2rem 3rem 21rem;
    text-align: left; }
    .legend .carousel-content button {
      padding: 0 1rem;
      font-size: 1.5rem;
      background-color: #86b128 !important; }
  .legend .carousel-title {
    margin: 1rem 0;
    font-size: 6rem;
    line-height: 4.5rem;
    font-weight: 700;
    top: calc(25% - 35px); }
  .legend .carousel-info {
    margin: 0.5rem 0;
    font-size: 2rem;
    line-height: 2rem; }

.sidebar-carousel {
  z-index: 9999;
  position: absolute;
  background-color: white;
  width: 15%;
  max-width: 20rem;
  min-width: 17rem;
  top: calc(25% - 35px);
  left: 6%;
  border-radius: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; }
  .sidebar-carousel .sidebar-title {
    color: white;
    background-color: #86b128 !important;
    border-bottom: 2px solid #86b128;
    border-radius: 5px 5px 0px 0px; }
    .sidebar-carousel .sidebar-title h1 {
      margin: 0;
      font-weight: 400;
      font-size: 2rem;
      text-align: center; }
  .sidebar-carousel .side-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    position: relative; }
    .sidebar-carousel .side-item.separator {
      border-bottom: 1px solid rgba(146, 148, 150, 0.3); }
    .sidebar-carousel .side-item a {
      position: absolute;
      right: 20px;
      top: 10px; }
    .sidebar-carousel .side-item form {
      width: 100%; }
      .sidebar-carousel .side-item form input {
        width: 100%; }
  .sidebar-carousel .side-title {
    margin-right: 3rem;
    margin-left: 1rem;
    font-size: 1.1rem;
    color: #252324; }
  .sidebar-carousel .side-img {
    width: 50px;
    height: auto; }
    .sidebar-carousel .side-img.arrow {
      margin-left: auto; }
  .sidebar-carousel .tracking-input {
    font-size: 1.2rem;
    width: 100%;
    min-height: 2.5rem;
    border-radius: 5px;
    padding-left: 1rem;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; }

@media screen and (min-width: 901px) and (max-width: 1400px) {
  .legend {
    top: calc(10% - 55px);
    left: 46% !important;
    width: 100% !important; }
    .legend .carousel-content {
      margin: 2rem 1rem 2rem 19rem;
      text-align: left; }
      .legend .carousel-content button {
        padding: 0 1rem;
        font-size: 1.2rem; }
    .legend .carousel-title {
      margin: 1rem 0;
      font-size: 4rem;
      line-height: 3rem;
      font-weight: 600;
      top: calc(25% - 35px); }
    .legend .carousel-info {
      margin: 0.5rem 0;
      font-size: 1.5rem;
      line-height: 1.5rem; }
  .sidebar-carousel {
    width: 16rem;
    left: 4%; }
    .sidebar-carousel .sidebar-title h1 {
      font-size: 1.5rem; }
    .sidebar-carousel .side-item {
      padding: 0.5rem; }
    .sidebar-carousel .side-title {
      font-size: 1rem;
      line-height: 1.2rem; }
    .sidebar-carousel .side-img {
      width: 30px; } }
