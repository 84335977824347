@import url("https://fonts.googleapis.com/css?family=Cairo:200,300,400,600,700,900");
@import url(~react-leaflet-markercluster/dist/styles.min.css);
* html,
body,
select {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background: white !important; }

button {
  font-family: "Cairo", sans-serif;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  background: white; }

/* Form generals */
.basic-select .select__control {
  border-radius: 0; }
