.home-map-pickup {
  position: relative; }
  .home-map-pickup .map-container {
    min-height: 400px;
    width: 100%; }

.home-map-pickup table {
  color: #86b128;
  padding: 10%;
  margin-top: 1em;
  margin-left: 0.5em;
  margin-right: 2em; }

.div-container-table {
  padding-right: 0px; }

.container-banner {
  width: 100%; }

.container-banner table thead tr th {
  text-align: center;
  color: #86b128;
  font-weight: 700;
  font-size: 0.9em;
  background-color: transparent !important;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-banner table thead {
  border-right-width: 10px; }

.container-banner thead {
  background-color: transparent !important;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-table {
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  height: 430px; }

.container-banner table tbody tr td {
  font-size: 0.7em !important;
  padding: 0.5em;
  border-bottom: 1px solid #efe9e9;
  border-right: 1px solid #efe9e9; }

.container-banner .special-border {
  border-bottom: 0px !important; }

.container-banner .special-border1 {
  border-right: 0px !important; }

.container-banner .special-border2 {
  border-bottom: 0px !important;
  border-right: 0px !important; }

.th-left {
  text-align: left !important;
  padding: 0.5em; }

.avisos {
  color: #86b128 !important;
  font-size: 0.8em;
  font-weight: bolder;
  margin-top: 3%;
  margin-left: 1em; }

.info-box {
  padding: 5px 10px;
  background: #fff;
  width: 200px !important; }
  .info-box h2 {
    color: #59ac54; }
  .info-box p {
    margin: 5px 0; }
    .info-box p.mb-2 {
      margin-bottom: 10px; }

@media screen and (max-width: 1300px) {
  .home-map-pickup .agencies-form {
    top: 60px;
    left: 20px;
    width: 400px; } }

.geosuggest__suggests--hidden {
  display: none; }

.geosuggest {
  position: relative; }
  .geosuggest .geosuggest__suggests-wrapper {
    z-index: 200;
    position: absolute;
    background: white;
    width: 100%; }
    .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests {
      border: 1px solid #c2c2c2;
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0; }
      .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item {
        padding: 3px 10px;
        border-bottom: 1px solid #c2c2c2;
        cursor: pointer; }
        .geosuggest .geosuggest__suggests-wrapper .geosuggest__suggests .geosuggest__item:hover {
          background: #f1f1f2; }

@media screen and (max-width: 900px) {
  .container-banner .container-table {
    height: 270px;
    padding-right: 1.5em; }
  .avisos {
    font-size: 0.7em; } }
